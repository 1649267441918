document.addEventListener('DOMContentLoaded', () => {
    const farbwechslerLogo = new Farbwechsler({
        trigger: "#entstehung",
        text: ".header__element--logo",
        containerText: ".header__element-link",
        slider: ".projekt__konzept-wrap"
    });
    const farbwechslerMenuepunkte = new Farbwechsler({
        trigger: "#entstehung",
        buttons: ".header__menuepunkt",
        slider: ".projekt__konzept-wrap"
    });

    farbwechslerLogo.farbeWechselnTextMitContainerUndSlider();
    farbwechslerMenuepunkte.farbeWechselnTextMitSlider();
});
